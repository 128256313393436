import React, { useEffect, useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';

import '../../../../style/css/rule.css'
import { routePrivacyPolicy } from '../../../../style/css/CmnStyle';
import { hpContext } from '../../HPContext'


// 利用規約
function TermOfService() {
  const { t } = useTranslation();
  const ReactGA = useContext(hpContext).ReactGA;

  const scrollHeadHeight = useContext(hpContext).scrollHeadHeight;
  const isWide = useContext(hpContext).isWide;


  useEffect(() => {
    // ログ：ページ
    ReactGA.event("pageRiyoukiyaku");
  }, [])


  return (
    <View style={{marginTop: scrollHeadHeight}}>

      <div className={isWide? 'wide-rule-wrap' : 'rule-wrap'}>

        {/* 利用規約 */}
        <h1 className='rule-title'>{t('kiyaku.title')}</h1>

        <div className='article'>
          {/* 利用規約 */}
          <h2 className='article-title'>{t('kiyaku.title')}</h2>
          {/* Skaters（以下「当社」といいます）が提供するサービス「Skaters」（以下「本サービス」といいます）の利用については、本規約に必ず同意のうえご利用ください。 */}
          <p className='article-content'>{t('kiyaku.premise')}</p>
        </div>

        <div className='article'>
          {/* 第1条（適用範囲） */}
          <h2 className='article-title'>{t('kiyaku.a1.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a1.l1')}</li>
              <li>{t('kiyaku.a1.l2')}</li>
              <li>{t('kiyaku.a1.l3')}</li>
              <li>{t('kiyaku.a1.l4')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第2条（定義） */}
          <h2 className='article-title'>{t('kiyaku.a2.title')}</h2>
          <div className='article-content'>
            <p>{t('kiyaku.a2.premise')}</p>
            <ol className='article-ol'>
              <li>{t('kiyaku.a2.l1')}</li>
              <li>{t('kiyaku.a2.l2')}</li>
              <li>{t('kiyaku.a2.l3')}</li>
              <li>{t('kiyaku.a2.l4')}</li>
              <li>{t('kiyaku.a2.l5')}</li>
              <li>{t('kiyaku.a2.l6')}</li>
              <li>{t('kiyaku.a2.l7')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第3条（会員登録） */}
          <h2 className='article-title'>{t('kiyaku.a3.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a3.l1')}</li>
              <li>{t('kiyaku.a3.l2')}</li>
              <li>{t('kiyaku.a3.l3')}</li>
              <li>
                {t('kiyaku.a3.l4.main')}
                <br />
                <div className='article-sub-list-wrap'>
                  <p>{t('kiyaku.a3.l4.sub1')}</p>
                  <p>{t('kiyaku.a3.l4.sub2')}</p>
                  <p>{t('kiyaku.a3.l4.sub3')}</p>
                  <p>{t('kiyaku.a3.l4.sub4')}</p>
                </div>
              </li>
              <li>
                {t('kiyaku.a3.l5.main')}
                <br />
                <div className='article-sub-list-wrap'>
                  <p>{t('kiyaku.a3.l5.sub1')}</p>
                  <p>{t('kiyaku.a3.l5.sub2')}</p>
                  <p>{t('kiyaku.a3.l5.sub3')}</p>
                  <p>{t('kiyaku.a3.l5.sub4')}</p>
                </div>
              </li>
              <li>{t('kiyaku.a3.l6')}</li>
              <li>{t('kiyaku.a3.l7')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第4条（IDおよびパスワードの管理） */}
          <h2 className='article-title'>{t('kiyaku.a4.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a4.l1')}</li>
              <li>{t('kiyaku.a4.l2')}</li>
              <li>{t('kiyaku.a4.l3')}</li>
              <li>{t('kiyaku.a4.l4')}</li>
              <li>{t('kiyaku.a4.l5')}</li>
              <li>{t('kiyaku.a4.l6')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第5条（利用者の責任） */}
          <h2 className='article-title'>{t('kiyaku.a5.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a5.l1')}</li>
              <li>{t('kiyaku.a5.l2')}</li>
              <li>{t('kiyaku.a5.l3')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第6条（退会） */}
          <h2 className='article-title'>{t('kiyaku.a6.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a6.l1')}</li>
              <li>{t('kiyaku.a6.l2')}</li>
              <li>{t('kiyaku.a6.l3')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第7条（利用制限および登録解除） */}
          <h2 className='article-title'>{t('kiyaku.a7.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>
                {t('kiyaku.a7.l1.main')}
                <br />
                <div className='article-sub-list-wrap'>
                  <p>{t('kiyaku.a7.l1.sub1')}</p>
                  <p>{t('kiyaku.a7.l1.sub2')}</p>
                  <p>{t('kiyaku.a7.l1.sub3')}</p>
                  <p>{t('kiyaku.a7.l1.sub4')}</p>
                  <p>{t('kiyaku.a7.l1.sub5')}</p>
                  <p>{t('kiyaku.a7.l1.sub6')}</p>
                  <p>{t('kiyaku.a7.l1.sub7')}</p>
                  <p>{t('kiyaku.a7.l1.sub8')}</p>
                </div>
              </li>
              <li>{t('kiyaku.a7.l2')}</li>
              <li>{t('kiyaku.a7.l3')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第8条（禁止事項） */}
          <h2 className='article-title'>{t('kiyaku.a8.title')}</h2>
          <div className='article-content'>
            <p>{t('kiyaku.a8.premise')}</p>
            <ol className='article-ol'>
              <li>{t('kiyaku.a8.l1')}</li>
              <li>{t('kiyaku.a8.l2')}</li>
              <li>{t('kiyaku.a8.l3')}</li>
              <li>{t('kiyaku.a8.l4')}</li>
              <li>{t('kiyaku.a8.l5')}</li>
              <li>{t('kiyaku.a8.l6')}</li>
              <li>{t('kiyaku.a8.l7')}</li>
              <li>{t('kiyaku.a8.l8')}</li>
              <li>{t('kiyaku.a8.l9')}</li>
              <li>{t('kiyaku.a8.l10')}</li>
              <li>{t('kiyaku.a8.l11')}</li>
              <li>{t('kiyaku.a8.l12')}</li>
              <li>{t('kiyaku.a8.l13')}</li>
              <li>{t('kiyaku.a8.l14')}</li>
              <li>{t('kiyaku.a8.l15')}</li>
              <li>{t('kiyaku.a8.l16')}</li>
              <li>{t('kiyaku.a8.l17')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第9条（サービス提供の停止） */}
          <h2 className='article-title'>{t('kiyaku.a9.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>
                {t('kiyaku.a9.l1.main')}
                <br />
                <div className='article-sub-list-wrap'>
                  <p>{t('kiyaku.a9.l1.sub1')}</p>
                  <p>{t('kiyaku.a9.l1.sub2')}</p>
                  <p>{t('kiyaku.a9.l1.sub3')}</p>
                  <p>{t('kiyaku.a9.l1.sub4')}</p>
                  <p>{t('kiyaku.a9.l1.sub5')}</p>
                </div>
              </li>
              <li>{t('kiyaku.a9.l2')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第10条（本サービスの変更、終了） */}
          <h2 className='article-title'>{t('kiyaku.a10.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a10.l1')}</li>
              <li>{t('kiyaku.a10.l2')}</li>
              <li>{t('kiyaku.a10.l3')}</li>
              <li>{t('kiyaku.a10.l4')}</li>
              <li>{t('kiyaku.a10.l5')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第11条（保証の否認および免責事項）*/}
          <h2 className='article-title'>{t('kiyaku.a11.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a11.l1')}</li>
              <li>{t('kiyaku.a11.l2')}</li>
              <li>{t('kiyaku.a11.l3')}</li>
              <li>{t('kiyaku.a11.l4')}</li>
              <li>{t('kiyaku.a11.l5')}</li>
              <li>{t('kiyaku.a11.l6')}</li>
              <li>{t('kiyaku.a11.l7')}</li>
              <li>{t('kiyaku.a11.l8')}</li>
              <li>{t('kiyaku.a11.l9')}</li>
              <li>{t('kiyaku.a11.l10')}</li>
              <li>{t('kiyaku.a11.l11')}</li>
              <li>{t('kiyaku.a11.l12')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第12条（位置情報の利用） */}
          <h2 className='article-title'>{t('kiyaku.a12.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a12.l1')}</li>
              <li>{t('kiyaku.a12.l2')}</li>
              <li>{t('kiyaku.a12.l3')}</li>
              <li>{t('kiyaku.a12.l4')}</li>
              <li>{t('kiyaku.a12.l5')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第13条（マップ上の情報の管理） */}
          <h2 className='article-title'>{t('kiyaku.a13.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a13.l1')}</li>
              <li>{t('kiyaku.a13.l2')}</li>
              <li>{t('kiyaku.a13.l3')}</li>
              <li>
                {t('kiyaku.a13.l4.main')}
                <br />
                <div className='article-sub-list-wrap'>
                  <p>{t('kiyaku.a13.l4.sub1')}</p>
                  <p>{t('kiyaku.a13.l4.sub2')}</p>
                  <p>{t('kiyaku.a13.l4.sub3')}</p>
                </div>
              </li>
              <li>{t('kiyaku.a13.l5')}</li>
              <li>{t('kiyaku.a13.l6')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第14条（寄付・広告） */}
          <h2 className='article-title'>{t('kiyaku.a14.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a14.l1')}</li>
              <li>{t('kiyaku.a14.l2')}</li>
              <li>{t('kiyaku.a14.l3')}</li>
              <li>{t('kiyaku.a14.l4')}</li>
              <li>{t('kiyaku.a14.l5')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第15条（個人情報の取扱い） */}
          <h2 className='article-title'>{t('kiyaku.a15.title')}</h2>
          <div className='article-content'>
            <p>
              {t('kiyaku.a15.before')}
              <a href={routePrivacyPolicy} target='_blank'>{t('privacy.title')}</a>
              {t('kiyaku.a15.after')}
            </p>
          </div>
        </div>

        <div className='article'>
          {/* 第16条（利用環境の整備） */}
          <h2 className='article-title'>{t('kiyaku.a16.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a16.l1')}</li>
              <li>{t('kiyaku.a16.l2')}</li>
              <li>{t('kiyaku.a16.l3')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第17条（統計データ等の閲覧、利用） */}
          <h2 className='article-title'>{t('kiyaku.a17.title')}</h2>
          <div className='article-content'>
            <p>{t('kiyaku.a17.content')}</p>
          </div>
        </div>

        <div className='article'>
          {/* 第18条（通知または連絡） */}
          <h2 className='article-title'>{t('kiyaku.a18.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a18.l1')}</li>
              <li>{t('kiyaku.a18.l2')}</li>
              <li>{t('kiyaku.a18.l3')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第19条（本サービスの権利帰属） */}
          <h2 className='article-title'>{t('kiyaku.a19.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a19.l1')}</li>
              <li>{t('kiyaku.a19.l2')}</li>
              <li>{t('kiyaku.a19.l3')}</li>
              <li>{t('kiyaku.a19.l4')}</li>
              <li>{t('kiyaku.a19.l5')}</li>
              <li>
                {t('kiyaku.a19.l6.main')}
                <br />
                <div className='article-sub-list-wrap'>
                  <p>{t('kiyaku.a19.l6.sub1')}</p>
                  <p>{t('kiyaku.a19.l6.sub2')}</p>
                  <p>{t('kiyaku.a19.l6.sub3')}</p>
                </div>
              </li>
              <li>{t('kiyaku.a19.l7')}</li>
              <li>{t('kiyaku.a19.l8')}</li>
              <li>{t('kiyaku.a19.l9')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第20条（権利義務の譲渡の禁止） */}
          <h2 className='article-title'>{t('kiyaku.a20.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a20.l1')}</li>
              <li>{t('kiyaku.a20.l2')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第21条（分離可能性） */}
          <h2 className='article-title'>{t('kiyaku.a21.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a21.l1')}</li>
              <li>{t('kiyaku.a21.l2')}</li>
            </ol>
          </div>
        </div>

        <div className='article'>
          {/* 第22条（準拠法と裁判管轄） */}
          <h2 className='article-title'>{t('kiyaku.a22.title')}</h2>
          <div className='article-content'>
            <ol className='article-ol'>
              <li>{t('kiyaku.a22.l1')}</li>
              <li>{t('kiyaku.a22.l2')}</li>
            </ol>
          </div>
        </div>

        <div className='article history-wrap'>
          {/* 改訂履歴 */}
          <h3 className='history-title'>{t('kiyaku.history.title')}</h3>
          <ul className='history'>
            <li>{t('kiyaku.history.v1')}</li>
            <li>{t('kiyaku.history.v2')}</li>
            <li>{t('kiyaku.history.v3')}</li>
            <li>{t('kiyaku.history.v4')}</li>
          </ul>
        </div>

      </div>

    </View>
  );
}

export default TermOfService;